<!-- TEMPLATE -->
<template>
  <v-row>
    <v-col sm="12">
      <v-card class="mx-auto">
        <v-list-item class="primary">
          <v-list-item-content>
            <v-list-item-title class="headline text-left white--text"
              >Summary</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
        <template>
          <v-data-table
            :headers="headers"
            :items="summary"
            :items-per-page="5"
            class="pt-4"
          >
            <template v-slot:item.Regular="{item}">{{
              item.Regular | decimalToTime
            }}</template>
            <template v-slot:item.Overtime="{item}">{{
              item.Overtime | decimalToTime
            }}</template>
            <template v-slot:item.Leave="{item}">{{
              item.Leave | decimalToTime
            }}</template>
            <template v-slot:item.Holiday="{item}">{{
              item.Holiday | decimalToTime
            }}</template>
            <template v-slot:item.UsedComp="{item}">{{
              item.UsedComp | decimalToTime
            }}</template>
            <template v-slot:item.UsedFlex="{item}">{{
              item.UsedFlex | decimalToTime
            }}</template>
            <template v-slot:item.Total="{item}">{{
              item.Total | decimalToTime
            }}</template>
            <template v-slot:item.Expected="{item}">{{
              item.Expected | decimalToTime
            }}</template>
          </v-data-table>
        </template>
      </v-card>
    </v-col>
  </v-row>
</template>

<!-- SCRIPTS -->
<script>
import {debounce, isEqual, filter, sortBy, range} from 'lodash'
import {get, sync, commit, call} from 'vuex-pathify'
import {authMethods, authComputed} from '@state/helpers'
import TimeSheet from '@classes/TimeSheet'

export default {
  data: () => ({
    summary: [],
    headers: [
      {
        text: 'Regular Hours',
        align: 'center',
        sortable: 'true',
        value: 'Regular',
      },
      {
        text: 'Overtime Hours',
        align: 'center',
        sortable: 'true',
        value: 'Overtime',
      },
      {
        text: 'Leave Hours',
        align: 'center',
        sortable: 'true',
        value: 'Leave',
      },
      {
        text: 'Holiday Hours',
        align: 'center',
        sortable: 'true',
        value: 'Holiday',
      },
      {
        text: 'Used Comp',
        align: 'center',
        sortable: 'true',
        value: 'UsedComp',
      },
      {
        text: 'Used Flex',
        align: 'center',
        sortable: 'true',
        value: 'UsedFlex',
      },
      {
        text: 'Total Hours',
        align: 'center',
        sortable: 'true',
        value: 'Total',
      },
      {
        text: 'Expected Hours',
        align: 'center',
        sortable: 'true',
        value: 'Expected',
      },
    ],
  }),
  filters: {
    decimalToTime(d) {
      var decimalTime = parseFloat(d) * 60 * 60
      var hours = Math.floor(decimalTime / (60 * 60))

      decimalTime = decimalTime - hours * 60 * 60
      var minutes = Math.round(decimalTime / 60)

      if (minutes < 10) {
        minutes = '0' + minutes
      }
      return hours + ':' + minutes
    },
  },
  created() {
    this.getNewMonthlyTotal()
  },
  computed: {
    ...authComputed,
    timesheetId() {
      return parseInt(this.$route.params.id)
    },
  },
  methods: {
    handleError: call('errors/handleError'),
    timeToDecimal(t) {
      try {
        var arr = t.split(':')
        if (arr.length == 2 && arr[1] != '') {
          return parseFloat(parseInt(arr[0], 10) + parseInt(arr[1], 10) / 60)
        } else {
          return parseInt(arr[0], 10)
        }
      } catch (err) {
        return parseInt(t, 10)
      }
    },

    getNewMonthlyTotal() {
      let url = `TimeSheet/MonthlyTotalByTimeSheet/${this.timesheetId}`
      this.$axios.get(url).then(
        (res) => {
          this.summary = [res.data.Hours]
        },
        (error) => {
          this.handleError(error)
          this.error = error
          console.error(error)
        }
      )
    },
  },
}
</script>

<!-- STYLES -->
<style lang="scss">
//@import '@design';
</style>