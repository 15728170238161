<!-- TEMPLATE -->
<template>
    <v-container fluid>
      <v-breadcrumbs :items="breadcrumbs" divider=">" large></v-breadcrumbs>
            <v-card v-if="timesheetStatusText && timesheetUserName" class="mx-auto">
        <v-list-item class="primary">
          <v-list-item-content
            <v-list-item-title
              class="headline text-left white--text"
            >{{timesheetUserName}} - {{timesheetStatusText}}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-card>
      <TimeSheetDetails :timeSheetId="timesheetId"></TimeSheetDetails>
      <TimeSheetSummary></TimeSheetSummary>
      <!-- SUBMIT BTN -->
      <v-row v-if="hasPermission && (!timeSheetStatus.StatusID || timeSheetStatus.StatusID == 5)" justify="end">
        <v-col sm="auto">
          <v-btn @click="submitTimesheetStatus" color="primary">
            <v-icon left>mdi-calendar-export</v-icon>Submit Timesheet
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
</template>
<!-- SCRIPTS -->
<script>
import {debounce, isEqual, filter, sortBy, range} from 'lodash'
import dayjs from 'dayjs'
import {get, sync, commit, call} from 'vuex-pathify'
import {authMethods, authComputed} from '@state/helpers'
import TimeSheetDetails from '@components/time/timesheetDetails'
import TimeSheetSummary from '@components/time/timesheetSummary'
import TimeSheetEntry from '@classes/TimeSheetEntry'
import TimeSheet from '@classes/TimeSheet'
import TimeSheetStatus from '../../classes/TimeSheetStatus'
export default {
  name: 'TimesheetSubmit',
  page: {
    title: 'Submit Timesheet',
  },
  components: {TimeSheetDetails, TimeSheetSummary},
  data: () => ({
    timeSheetStatus: {},
    timeSheet: {},
    timeSheetUser: {},
  }),
  created() {
    this.getTimesheetStatus()
  },
  computed: {
    ...authComputed,
    hasPermission() {
      if (this.timeSheetUser) {
        if (
          this.isSuperUser ||
          (this.timeSheetUser.SupervisorID == this.currentUser.ID &&
            this.timeSheetStatus.StatusID) ||
          (this.timeSheetUser.TimesheetVerifierID == this.currentUser.ID &&
            this.timeSheetStatus.StatusID == 1) ||
          (this.timeSheetUser.ID == this.currentUser.ID &&
            (!this.timeSheetStatus.StatusID ||
              this.timeSheetStatus.StatusID == 5))
        ) {
          return true
        }
      }
      return false
    },
    timesheetUserName() {
      if (this.timeSheetUser) {
        return this.timeSheetUser.FirstName + ' ' + this.timeSheetUser.LastName
      }
    },
    timesheetStatusText() {
      if (this.timeSheetStatus && this.timeSheetStatus.Status) {
        return this.timeSheetStatus.Status.Description
      } else {
        return 'Unsubmitted'
      }
    },
    timesheetId() {
      return parseInt(this.$route.params.id)
    },
    breadcrumbs() {
      return [
        {
          text: 'Approval',
          disabled: false,
          to: '/timesheetsApproval',
        },
      ]
    },
    submitStatus() {
      if (!this.timeSheetUser.TimesheetVerifierID) {
        return 2
      } else {
        return 1
      }
    },
  },
  methods: {
    createTimeSheetStatus: call('timeSheetStatus/createTimeSheetStatus'),
    handleError: call('errors/handleError'),
    getTimesheetUser() {
      let url = `/User/TimeSheet/${this.timesheetId}`
      this.$axios.get(url).then(
        (res) => {
          if (res.data) {
            this.timeSheetUser = res.data
          } else {
            this.timeSheetUser = {}
          }
        },
        (error) => {
          this.handleError(error)
          this.error = error
          console.error(error)
        }
      )
    },
    getTimesheetStatus() {
      if (this.timesheetId) {
        let url = `/TimeSheetStatus/TimeSheet/${this.timesheetId}`
        this.$axios.get(url).then(
          (res) => {
            if (res.data) {
              this.timeSheetStatus = new TimeSheetStatus(res.data)
              this.timeSheetUser = res.data.TimeSheet.User
            } else {
              this.timeSheetStatus = {}
              this.getTimesheetUser()
            }
          },
          (error) => {
            this.handleError(error)
            this.error = error
            console.error(error)
          }
        )
      }
    },
    submitTimesheetStatus() {
      let newStatus = new TimeSheetStatus({
        TimeSheetID: this.timesheetId,
        StatusID: this.submitStatus,
        UserID: this.currentUser.ID,
        StatusDate: dayjs().toDate().toISOString().substr(0, 10),
      })
      this.createTimeSheetStatus(newStatus.root()).then((res) => {
        if (res.data) {
          this.$router.push({
            name: 'timesheetsApproval',
          })
        }
      })
    },
  },
}
</script>

<!-- STYLES -->
<style lang="scss">
//@import '@design';
</style>