import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,[_c(VCol,{attrs:{"sm":"12"}},[_c(VCard,{staticClass:"mx-auto"},[_c(VListItem,{staticClass:"primary"},[_c(VListItemContent,[_c(VListItemTitle,{staticClass:"headline text-left white--text"},[_vm._v("Summary")])],1)],1),[_c(VDataTable,{staticClass:"pt-4",attrs:{"headers":_vm.headers,"items":_vm.summary,"items-per-page":5},scopedSlots:_vm._u([{key:"item.Regular",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("decimalToTime")(item.Regular)))]}},{key:"item.Overtime",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("decimalToTime")(item.Overtime)))]}},{key:"item.Leave",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("decimalToTime")(item.Leave)))]}},{key:"item.Holiday",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("decimalToTime")(item.Holiday)))]}},{key:"item.UsedComp",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("decimalToTime")(item.UsedComp)))]}},{key:"item.UsedFlex",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("decimalToTime")(item.UsedFlex)))]}},{key:"item.Total",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("decimalToTime")(item.Total)))]}},{key:"item.Expected",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("decimalToTime")(item.Expected)))]}}])})]],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }